import React from 'react'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import { graphql } from 'gatsby'
import StyledGallery from '../../../components/StyledGallery/StyledGallery'
import ReturnToPrevPage from '../../../components/ReturnToPrevPage/ReturnToPrevPage'
import Img from 'gatsby-image'
import MonumentContentWrapper from '../../../components/MonumentContentWrapper/MonumentContentWrapper'
import { Header1 } from '../../../components/Headers/Headers'
import SEO from '../../../components/seo'
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer'

const DawnaKarczmaWStanislawowiePage = ({ data }) => {
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  return (
    <ArchiveWrapper>
      <SEO title="Dawna karczma w Stanisławowie" />
      <ReturnToPrevPage to={'/gminy/stanislawow'}>
        Materiały dotyczące gminy Stanisławów
      </ReturnToPrevPage>
      <Header1>Dawna karczma w Stanisławowie</Header1>
      <Img fluid={data.file.childImageSharp.fluid} />
      <MonumentContentWrapper>
        <VideoPlayer videoId={895166375} />
        <p>
          Wielki Gościniec Litewski jest hasłem, które pobudza wyobraźnię
          każdego, kto choć trochę interesuje się historią. Szlak prowadzący z
          Warszawy do Wilna miał ogromne znaczenie dla łączności Korony Polskiej
          i Wielkiego Księstwa Litewskiego. Aby kontakty polityczne, wymiana
          gospodarcza i kulturowa kwitła niezbędne było zaplecze. Jednym z jego
          śladów na terenie powiatu jest stanisławowska karczma.
        </p>
        <p>
          Obecnie Wielki Gościniec Litewski jest już historią. Trasy, którymi
          wiódł nie są już kluczowymi w komunikacji między Polską a Litwą.
          Lokację karczm wyznaczał rytm wytrzymałości konia i jeźdźca. Wraz z
          rozwojem technologii karczmy zaczęły tracić na znaczeniu, by
          bezpowrotnie zmienić swój charakter.
        </p>
        <p>
          Mieszcząc obecnie Gminny Ośrodek Kultury swoją zadbaną elewacją daje
          świadectwo czasów. XIX. wieczna bryła zachowuje swoje oryginalne
          kształty. Zniszczenia drugiej wojny światowej i zmiany realiów
          technologicznych odmieniły jednak charakter wnętrza. Obecna główna
          obszerna sala, która przyjmuje gości GOKu oraz imprez
          okolicznościowych jest dawną… stajnią. Część w której wypoczywali i
          jadali podróżni znajdowała się w części wschodniej.
        </p>
        <p>
          Na zachód od dawnej karczmy mieści się dawny rynek miejski, który
          dobrze ilustruje znaczenie miejscowości. Na mapie Wielkiego Gościńca
          Litewskiego znajduje się nie tylko z powodów geograficzny ale też
          funkcjonującej tkanki małego miasta, która zapewniał dostęp do
          rzemieślników mogących pomóc jak i również handlować.
        </p>
      </MonumentContentWrapper>
      <StyledGallery images={images} />
    </ArchiveWrapper>
  )
}

export const query = graphql`
  {
    file(
      name: { regex: "/featured/" }
      relativePath: { regex: "/stanislawow/dawna-karczma/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFile(
      filter: { relativePath: { regex: "/stanislawow/dawna-karczma/" } }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default DawnaKarczmaWStanislawowiePage
